<template>
  <div>
    <ed-table
       strModel="Cadastro/Cid"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getFuncao"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getFuncao();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Identificação",
          sortable: true,
          value: "strCodigo",
        },
        {
          text: "Descrição",
          sortable: true,
          collapse: 50,
          value: "strDescricao",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "cadastro.cid.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "cadastro.cid.edit",
        params: { intId: data.intId },
      });
    },

    getFuncao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request.get("Cadastro/Cid", this.formFiltros).then((objResult) => {
        if (objResult.status == 200) {
          this.objPagination = objResult.result;
          this.loading = false;

          objResult.result.data.forEach((objFuncao) => {
            let item = {
              intId: objFuncao.intId,
              strCodigo: objFuncao.strCodigo,
              strDescricao: objFuncao.strDescricao,
              _item: objFuncao,
            };

            this.arrayRows.push(item);
          });
        }
      });
    },
  },
};
</script>
